import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PrivacyPolicyComponent} from "./partials/shared-modules/global/privacy-policy/privacy-policy.component";
import {MobileMapComponent} from "./modules/user/order-creation/address-selection/mobile-map/mobile-map.component";
import {
  MobilePrivacyComponent
} from "./partials/shared-modules/global/privacy-policy/mobile-privacy/mobile-privacy.component";
import {MobileGuard} from "./guards/mobile.guard";
import {AppInfoComponent} from "./partials/components/app-info/app-info.component";

const routes: Routes = [
  {path: 'app', component: AppInfoComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'select-address', component: MobileMapComponent},
  {path: 'privacy-policy/:title', component: MobilePrivacyComponent, canActivate: [MobileGuard]},
  {
    path: '',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {ResourceUrlInterface} from "@interfaces/components/resource-url.interface";
import {AddressInterface} from "@interfaces/orders/address.interface";
import {OrderStatusEnum} from "@interfaces/orders/enums/order-status.enum";
import {PaginationResponseInterface} from "@interfaces/global/pagination.interface";
import {ProductSimpleInterface} from "@interfaces/catalog/products.interface";
import {SberbankPaymentStatusEnum} from "@interfaces/orders/enums/sberbank-payment-status.enum";
import {DeliverTimeTypeEnum} from "@interfaces/orders/enums/deliver-status.enum";

export interface OrderItemInterface {
  count: number
  totalPrice: number
  productDetails: ProductSimpleInterface
}

export interface OrderSimpleInterface {
  receiver: ReceiverInfoInterface,
  diapason: number,
  sender: SenderInfoInterface,
  deliverOn: string
  note: string
  address: AddressInterface
  id: number
  totalCount: number
  totalAmount: number
  deliveryPrice: number
  status: OrderStatusEnum,
  sberbankPaymentStatus: SberbankPaymentStatusEnum
  totalPreparationTime: number
  deliveredOn?: string
  createdAt: string,
  orderReview: OrderReviewInterface
}

export interface SingleOrderInterface extends OrderSimpleInterface {
  orderItems: Array<OrderItemInterface>

}

export interface OrderListInterface extends OrderSimpleInterface {
  isSelected?: boolean
  resourceUrls: Array<ResourceUrlInterface>
}

export interface OrderPaginationInterface extends PaginationResponseInterface {
  content: Array<OrderListInterface>
}

export interface OrderCreateRequestInterface extends ReceiverInfoInterface, DeliveryDateInterface {
  note?: string
  address?: AddressInterface,
  promoCode?: string,
  bonusAmount?: number
}

export interface ReceiverInfoInterface {
  receiverName: string
  receiverPhoneNumber?: string
}

export interface SenderInfoInterface {
  id: number,
  firstName: string,
  lastName: string,
  phoneNumber: string
}

export interface DeliveryDateInterface {
  deliverOn?: string
  deliverOnStatus: DeliverTimeTypeEnum
}

export enum PaymentTypesEnum {
  CARD = 'CARD',
  SBER_PAY = 'SBER_PAY',
  SBP = 'SBP'
}

export interface PaymentDataInterface {
  paymentType: PaymentTypesEnum,
  sberPay?: {
    osType: string,
    deepLink: string
  }
}

export enum OrderReviewStatusEnum {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  NONE = 'NONE',
  TIMEOUT = 'TIMEOUT'
}


export interface OrderReviewInterface {
  expireTime: string,
  status: OrderReviewStatusEnum,
  resourceUrl: string
}

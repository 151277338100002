import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

//Services
import {CityService} from "@services/city/city.service";
import {BrowserService} from "@services/components/browser.service";

//Constants
import {MapTypeEnum} from "@interfaces/orders/enums/map-configs.enum";
import {FullAddressLongLatInterface} from "@interfaces/orders/address.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {CityInterface} from "@interfaces/components/cities.interface";

// Components
import {ConfirmAddressDialogComponent} from "../confirm-address-dialog/confirm-address-dialog.component";
import { MapComponent } from '../map/map.component';
import { ClearButtonComponent } from '../../../../../partials/shared-modules/global/clear-button/clear-button.component';

@Component({
    selector: 'app-map-dialog',
    templateUrl: './map-dialog.component.html',
    styleUrls: ['./map-dialog.component.scss'],
    standalone: true,
    imports: [ClearButtonComponent, MapComponent]
})
export class MapDialogComponent {
  longLat: Array<number> = [];
  mapConfigType = MapTypeEnum.EXTRA;
  city!: CityInterface;

  constructor(
    private cityService: CityService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MapDialogComponent>,
    private browserService: BrowserService,
  ) {
    const city = this.cityService.getCityModelFromLocalStorage();
    if (city) {
      const {latitude, longitude} = 'city' in city ? city.city : city.address;
      if (latitude && longitude) {
        this.longLat.push(+latitude, +longitude);
      }
    }
  }

  openConfirmationDialogForAddress(data: { fullAddressObject: FullAddressLongLatInterface }): void {
    const dialogRef = this.dialog.open(ConfirmAddressDialogComponent, {
      data,
      maxWidth: this.browserService.isTabletDevice ? '60vw' : '80vw',
    })
    dialogRef.afterClosed().subscribe((address: FavoriteAddressInterface) => {
      if (!address) {
        return;
      }
      this.dialogRef.close(address);
    })
  }

  close(): void {
    this.dialogRef.close()
  }

}






<div class="back_button br_50 flex_center_align_center pointer"
     (click)="closeComponentEmitter.emit()">
  <img src="assets/images/mobile_icons/navigate-back_icon.svg">
</div>
<div class="app_input" [ngClass]="{'confirm_address': (type === searchLocationTypeEnums.CONFIRM_ADDRESS)}">
  <img src="assets/images/icons/search-icon.svg" class="icon pointer" *ngIf="type ===searchLocationTypeEnums.FROM_MAP ">
  <input type="text" placeholder="{{'input_placeholder.type_your_address' | translate}}"
         #searchInput
         [matAutocomplete]="yandexMapSelect"
         [value]="type === searchLocationTypeEnums.CONFIRM_ADDRESS ? fullAddress: ''"
         (keyup)="searchAddressAndMakeLongLat(searchInput.value)"
  >
  <mat-autocomplete autoActiveFirstOption #yandexMapSelect="matAutocomplete">
    <mat-option class="mat_auto_complete_option" *ngFor="let location of filteredFavoriteAddressList"
                [value]="location.fullAddress"
                (onSelectionChange)="addressSelectionFavoriteAddress(location)">
      <div class="flex_between_align_center w_100 border_bottom">
        <div class="flex_between_align_center">
          <img src="assets/images/icons/orders/favoriteAddresimg.svg">
          <div class="ml_12">
            <div class="bold_700" [ngClass]="[mobileSearch ? 'text_normal' : 'text_normal_sub']">
              {{location.fullAddress}}
            </div>
          </div>
        </div>
        <img src="assets/images/icons/orders/more_dropdown.svg">
      </div>
    </mat-option>
    <mat-option class="searched_values" *ngFor="let option of locations"
                [value]="option.properties.get('name')"
                (onSelectionChange)="addressSelection(option)">
     <div class="label_text">
       {{option.properties.get('name')}},
       {{option.properties.get('description')}}
     </div>
    </mat-option>
  </mat-autocomplete>

  <img *ngIf="type === searchLocationTypeEnums.FROM_MAP" class="mr_16 ml_16 blocked_img pointer" src="assets/images/icons/clear.svg" (click)="searchInput.value=''">
</div>

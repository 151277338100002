import {Component, EventEmitter, Inject, Input, Optional, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {MatRadioButton} from '@angular/material/radio';
import {NgFor, NgIf, NgClass} from '@angular/common';

//Components
import {
  MapDialogComponent
} from "../../../../../modules/user/order-creation/address-selection/map-dialog/map-dialog.component";
import {
  ConfirmAddressDialogComponent
} from "../../../../../modules/user/order-creation/address-selection/confirm-address-dialog/confirm-address-dialog.component";

//Services
import {BrowserService} from "@services/components/browser.service";
import {AuthService} from "@services/auth/auth.service";
import {ShowMessageService} from "@services/messages/show-message.service";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {ConfirmationDialogService} from "@services/confirmation-dilog/confirmation-dilog.service";
import {CityService} from "@services/city/city.service";

//Constants
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {ActionTypeEnum} from "@interfaces/components/enums/action-type.enum";
import {CityInterface} from "@interfaces/components/cities.interface";
import {ConfirmationActionEnum} from "@interfaces/components/enums/confirmation-action.enum";

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatRadioButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatSlideToggle,
    FormsModule,
    NgIf,
    NgClass,
    TranslateModule
  ]
})
export class DeliveryAddressComponent {
  @Input() set data(data: any) {
    this.addressData = data
    this.checkAndGetUserFavoritesAddresses()
  }

  @Output() selectFavoriteAddressEvent = new EventEmitter<void>();
  @Output() closeBottomSheet = new EventEmitter<any>();
  checkAddress = false;
  favoriteAddress!: Array<FavoriteAddressInterface>;
  selectedFavoriteAddress!: FavoriteAddressInterface;
  disableSave = true;
  isMobile!: boolean
  actionTypeEnum = ActionTypeEnum;
  city!: FavoriteAddressInterface | CityInterface
  dialogOrBottomSheet!: MatDialogRef<DeliveryAddressComponent> | MatBottomSheetRef<DeliveryAddressComponent>;

  constructor(
    private dialog: MatDialog,
    private browserService: BrowserService,
    private authService: AuthService,
    private showMessageService: ShowMessageService,
    private requestService: RequestMethodsService,
    private confirmationDialogService: ConfirmationDialogService,
    private cityService: CityService,
    @Optional() dialogRef: MatDialogRef<DeliveryAddressComponent>,
    @Optional() bottomSheetRef: MatBottomSheetRef<DeliveryAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public addressData: { selectedAddress: FavoriteAddressInterface }
  ) {
    this.dialogOrBottomSheet = Object.keys(dialogRef).length === 0 ? bottomSheetRef : dialogRef;
    const address = this.cityService.getCityModelFromLocalStorage();
    if (address) {
      this.city = 'city' in address ? address.city : address.address;
    }
    this.isMobile = this.browserService.isMobileDevice;
    this.checkAndGetUserFavoritesAddresses();
  }

  checkAndGetUserFavoritesAddresses(): void {
    if (this.authService.getUserInfo()) {
      this.getFavoriteAddress();
    }
    if (this.addressData.selectedAddress || this.city) {
      this.disableSave = false;
    }
  }


  openCitySelectionDialog(): void {
    if (this.checkAddress) {
      this.browserService.isMobileDevice ? this.closeBottomSheet.emit({favoriteAddress: this.selectedFavoriteAddress}) : this.close(true);
    }
  }

  addNewAddress(): void {
    if (this.browserService.isMobileDevice) {
      this.closeBottomSheet.emit({favoriteAddress: null, openMobileMap: true})
      return;
    }

    const dialog = this.dialog.open(MapDialogComponent, {
      width: this.browserService.isTabletDevice ? '73vw' : '50vw',
      maxWidth: this.browserService.isTabletDevice ? '68vw' : '80vw'
    })
    dialog.afterClosed().subscribe((address: FavoriteAddressInterface) => {
      if(!address){
        return;
      }
      this.selectedFavoriteAddress = address;
      this.save();
    })
  }

  getFavoriteAddress(): void {
    this.requestService.get(`${EndpointConstant.favorite}-${EndpointConstant.addresses}`)
      .subscribe((response: Array<FavoriteAddressInterface>) => {
        this.favoriteAddress = response;
      })
  }


  selectFavoriteAddress(data: FavoriteAddressInterface): void {
    this.selectedFavoriteAddress = data
    this.disableSave = false;
  }

  save(): void {
    if (this.addressData.selectedAddress && !this.selectedFavoriteAddress) {
      this.close(this.addressData.selectedAddress);
    } else {
      this.close(this.selectedFavoriteAddress);
    }
  }

  actionEditOrDelete(type: ActionTypeEnum, item: FavoriteAddressInterface): void {
    if (type === ActionTypeEnum.TRASH) {
      if (!item.id) {
        return;
      }
      this.deleteFavoriteAddress(item.id);
    } else if (type === ActionTypeEnum.EDIT) {
      if (this.browserService.isMobileDevice) {
        this.closeBottomSheet.emit({favoriteAddress: item, openMobileMap: true})
      } else {
        this.openAsDialogEditFavorite(item)
      }
    }
  }

  openAsDialogEditFavorite(item: FavoriteAddressInterface): void {
    // const dialogRef = this.dialog.open(ConfirmAddressDialogComponent, {
    //   data: {fullAddressObject: item},
    //   maxWidth: this.browserService.isTabletDevice ? '60vw' : '80vw',
    // })
    // dialogRef.afterClosed().subscribe((address: FavoriteAddressInterface) => {
    //   if (address && this.addressData.selectedAddress.id === item.id) {
    //     this.close(address);
    //   }
    //   this.getFavoriteAddress()
    // })
  }


  deleteFavoriteAddress(favoriteAddressId: number) {
    this.confirmationDialogService.openConfirmationDialog({
      title: "notification.delete_address",
      content: "notification.sure_to_delete_address",
      firstButton: "notification.back",
      secondButton: "notification.delete"
    }).afterClosed().subscribe((confirm: ConfirmationActionEnum) => {
      if (confirm === ConfirmationActionEnum.APPROVE || !confirm) {
        return;
      }
      this.requestService.delete(`${EndpointConstant.favorite}-${EndpointConstant.addresses}/${favoriteAddressId}`)
        .subscribe(() => {
          if (favoriteAddressId === this.addressData.selectedAddress.id) {
            this.dialogOrBottomSheet.disableClose = true;
            this.cityService.clearCityFromStorage();
            this.disableSave = true;
          }
          this.getFavoriteAddress();
        })
    })
  }


  close(addressModel: FavoriteAddressInterface | null | boolean = null): void {
    if (this.dialogOrBottomSheet instanceof MatDialogRef) {
      this.dialogOrBottomSheet.close(addressModel)
    }
  }

  getData(): any {
    return {favoriteAddress: this.selectedFavoriteAddress} || null
  }
}

<div class="app_input w_100" *ngIf="inputData" [ngClass]="{disabled_input: inputData.disabled}">
  <span class="label" [hidden]="!inputData.data.value">{{(inputData.label || '') | translate}}</span>
  <input type="text"
         #input
         *ngIf="inputData.type !== 'textarea'"
         [readOnly]="inputData.readOnly"
         [required]="inputData.required==true"
         [pattern]="inputData.pattern || '.*'"
         [classList]="inputData.inputClass"
         [prefix]="inputData.prefix ? inputData.prefix : ''"
         [mask]=" inputData.prefix ? '(000)-000-00-00' : (inputData.mask ? inputData.mask : '')"
         [type]="inputData.type"
         [ngClass]="{hasValue: inputData.data.value}"
         [disabled]="inputData.disabled == true"
         [(ngModel)]="inputData.data.value"
         [leadZeroDateTime]="inputData.leadZeroDateTime!"
         [placeholder]="(inputData.placeholder || '') | translate"
  >
  <textarea
    *ngIf="inputData.type === 'textarea'"
    [required]="inputData.required!"
    [pattern]="inputData.pattern!"
    [disabled]="inputData.disabled!"
    [(ngModel)]="inputData.data.value"
    [placeholder]="(inputData.placeholder || '') | translate"
  ></textarea>
  <img *ngIf="(inputData.disabled || inputData.readOnly) && !inputData.dontResetValueInClearAction" class="mr_16 ml_16 blocked_img" src="assets/images/icons/orders/blocked.svg">
  <img *ngIf="inputData.clearButton" class="mr_16 ml_16 blocked_img pointer" src="assets/images/icons/clear.svg"
       (click)="clearInputField($event)">
</div>



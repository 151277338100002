<div class="bottom_navbar w_100 flex_between_align_center text_normal ">
  <div class="back_button br_50 flex_center_align_center pointer"
       [class.opacity_0]="navigationIcon === navigationIconTypeEnums.CATALOG"
       (click)="backToPreviousRoute()"
  >
    <img src="assets/images/mobile_icons/navigate-back_icon.svg" id='navigateBack'>
  </div>
  <div class="where_to_deliver_container pointer text_center" (click)="selectAddressToDeliverEvent.emit()">
    <div class="title font_13 bold_600 flex_center_align_center">
      <span>
        {{'navbar.address_of_delivery' | translate}}
      </span>
      <img src="assets/images/mobile_icons/arrow_left.svg">
    </div>
    <div class="address bold_700 font_15">
      {{selectedAddressName || ('navbar.select_address' | translate)}}
    </div>
  </div>
  <div class="search_container"></div>
</div>

import {
  afterNextRender,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {from} from "rxjs";
//components
import {MapDialogComponent} from "../map-dialog/map-dialog.component";
//services
import {CityService} from "@services/city/city.service";
import {IsInGeofenceService} from "@services/is-in-geofence/is-in-geofence.service";
import {BrowserService} from "@services/components/browser.service";
//constants
import {MapConfigsInterface} from "@interfaces/orders/map-configs.interface";
import {MapTypeEnum} from "@interfaces/orders/enums/map-configs.enum";
import {FullAddressLongLatInterface} from "@interfaces/orders/address.interface";
import {CityInterface, SimpleCityInterface} from "@interfaces/components/cities.interface";
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import { TranslateModule } from '@ngx-translate/core';
import { SearchLocationComponent } from '../search-location/search-location.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, SearchLocationComponent, NgClass, TranslateModule]
})
export class MapComponent {
  @ViewChild('searchInput') inputSearch!: ElementRef;
  @ViewChild('mapDiv') mapDiv!: ElementRef

  @Input() set configsForMap(configs: MapConfigsInterface) {
    if (!configs) {
      return;
    }
    this.configs = {...configs}
  };

  @Input() set city(setCity: CityInterface | FavoriteAddressInterface) {
    setCity ? this.longLat.push(+setCity?.latitude, +setCity?.longitude) : this.longLat.push(55.76, 37.64);
    this.cityModel = setCity;
  }

  @Input() favoriteAddress!: Array<FavoriteAddressInterface>;
  @Output() emitOpenMapDialogEventEmitter = new EventEmitter<void>();
  @Output() openConfirmationDialogForAddressEmitter = new EventEmitter<any>();
  @Output() chooseFavoriteAddressEmitter = new EventEmitter<FavoriteAddressInterface>();
  openSearchComponent = false;
  configs!: MapConfigsInterface;
  mapConfigsType = MapTypeEnum;
  ymaps: any;
  map: any;
  longLat: Array<number> = [];
  fromSearch!: boolean;
  fullAddressObject!: FullAddressLongLatInterface | null;
  radiusError: boolean = false;
  cityModel!: SimpleCityInterface | FavoriteAddressInterface;
  locations: Array<any> = [];

  constructor(private mapDialogRef: MatDialogRef<MapDialogComponent>,
              private dialog: MatDialog,
              private cityService: CityService,
              private isInGeofenceService: IsInGeofenceService,
              private browserService: BrowserService
  ) {
    afterNextRender(() => {
      this.ymaps = (window as any)['ymaps'];
      this.displayMap(this.configs)
    })
  }

  displayMap(configs: MapConfigsInterface): void {
    from(this.ymaps.ready()).subscribe(() => {
      this.map = new this.ymaps.Map(configs.id, {
        center: this.longLat,
        zoom: configs.type == this.mapConfigsType.MOBILE ? 9 : 10,
        controls: []
      });

      this.setCenterGetLongLat(configs);
      if (configs.type == this.mapConfigsType.EXTRA) {
        this.addControlZoom();
        this.addGeoLocationControl();
      } else if (configs.type == this.mapConfigsType.STANDARD) {
        this.map.behaviors.disable(['scrollZoom', 'drag'])
      }
      if (this.browserService.isMobileDevice || this.browserService.isTabletDevice) {
        this.map.events.add('click', () => this.triggerDivClick());
      }
    });
  }

  triggerDivClick() {
    const divElement: HTMLElement = this.mapDiv.nativeElement;
    const clickEvent = new Event('click', {bubbles: true});
    divElement.dispatchEvent(clickEvent);
  }

  handleNewLocation(data: FullAddressLongLatInterface | null) {
    if (!data) {
      return;
    }
    this.openSearchComponent = false;
    this.fullAddressObject = data;
    this.map.setCenter([data.latitude, data.longitude])
    this.map.setZoom(18);
  }

  openMapDialog(): void {
    if (this.configs.type !== this.mapConfigsType.STANDARD) {
      return;
    }
    this.emitOpenMapDialogEventEmitter.emit();
  }

  confirm(): void {
    if (!this.fullAddressObject) {
      return;
    }
    this.openConfirmationDialogForAddressEmitter.emit({
      fullAddressObject: this.fullAddressObject,
    })
  }


  addControlZoom(): void {
    const zoomControl = new this.ymaps.control.ZoomControl({
      options: {
        size: 'small',
        position: {
          bottom: 100,
          right: 34
        }
      },
    });
    this.map.controls.add(zoomControl);
  }

  addGeoLocationControl(): void {
    let geolocationControl = new this.ymaps.control.GeolocationControl({
      options: {
        noPlacemark: true,
        float: 'right',
        size: 'large',
        layout: this.ymaps.templateLayoutFactory.createClass(
          '<div class="custom_geolocation_icon pointer br_16"><img src="assets/images/icons/orders/geolocation.svg"></div>'
        )
      }
    });

    geolocationControl.events.add('locationchange', (event: any) => {
      const position = event.get('position');
      this.map.setCenter(position);
      this.map.setZoom(18)
    });

    this.map.controls.add(geolocationControl);

  }

  setCenterGetLongLat(configs: MapConfigsInterface): void {
    if (configs.type === MapTypeEnum.STANDARD) {
      return;
    }
    this.map.events.add('boundschange', (e: any) => {
      if (this.fromSearch) {
        this.fromSearch = false
        return;
      }

      const newCenter: Array<number> = e.get('newCenter');

      if (this.inputSearch?.nativeElement) {
        this.inputSearch.nativeElement.value = ''
      }

      this.ymaps.geocode(newCenter).then((result: any) => {
        const cityName = result.geoObjects.get(0).properties.get('metaDataProperty')?.GeocoderMetaData?.Address?.Components.find((
          (component: any) => component.kind === 'locality'))
          ?.name || '';
        this.fullAddressObject = this.isInGeofenceService.fromGeObjectGetLongLatAndAddress(
          result.geoObjects.get(0).properties.get('name'), newCenter, cityName
        );
        this.mapDiv.nativeElement.click();
      })
    });

  }

  setCenterOnBtnClick(): void {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {latitude, longitude} = position.coords;
        this.map.setCenter([latitude, longitude], 18);

      },
      (error) => {
        console.error('Error getting current position:', error);
      }
    );
  }

  searchLocation(searchedAddress: string): void {
    this.ymaps.geocode(searchedAddress).then((res: any) => {
      this.locations = res.geoObjects.toArray().filter((geoObject: any) => {
        const addressDetails = geoObject.properties.get('metaDataProperty').GeocoderMetaData.AddressDetails;
        const countryCode = addressDetails.Country?.CountryNameCode;
        return countryCode === 'RU';
      });
    });
  }

}

import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CityInterface, CityPaginationInterface} from "@interfaces/components/cities.interface";
import {RequestMethodsService} from "@services/request/request-methods.service";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-city-drop-down',
    templateUrl: './city-drop-down.component.html',
    styleUrls: ['./city-drop-down.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, NgFor, TranslateModule]
})
export class CityDropDownComponent {
  @Input() set data(data: {selected: number, isMobile: boolean }) {
    this.cityData = data;
    this.getCities();
  }

  searchedValue = '';
  arrayOfSearchedCity!: Array<CityInterface>;

  constructor(
    public dialogRef: MatDialogRef<CityDropDownComponent>,
    private requestService: RequestMethodsService,
    @Inject(MAT_DIALOG_DATA) public cityData: {selected: number, isMobile: boolean }) {
    this.getCities()
  }


  city!: CityInterface;
  selectedCityId!: number;

  getCities(): void {
    const searchedValue = this.searchedValue ? `&search=${this.searchedValue}` : '';
    this.requestService.get(`${EndpointConstant.cities}/${EndpointConstant.all}?page=1&size=100${searchedValue}`
    ).subscribe((response: CityPaginationInterface) => {
      this.arrayOfSearchedCity = response.content.filter(item=>item.name);
    }, (error) => console.log(error))
  }

  selectCity(city: CityInterface): void {
    this.cityData.isMobile ? this.city = city : this.dialogRef.close(city);
  }

  changeSelected() {
    this.cityData.selected = this.selectedCityId
  }

  getData(): any {
    return this.city
  }

}

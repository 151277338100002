import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {TranslateModule} from '@ngx-translate/core';
import {NgClass, NgIf} from '@angular/common';
//component
import {InputComponent} from "../../../../../partials/shared-modules/global/input/input.component";
import {SearchLocationComponent} from '../search-location/search-location.component';
import {ClearButtonComponent} from '../../../../../partials/shared-modules/global/clear-button/clear-button.component';
//constants
import {FavoriteAddressInterface} from "@interfaces/orders/favorite-address.interface";
import {EndpointConstant} from "@interfaces/global/endpoint.constant";
import {FullAddressLongLatInterface} from "@interfaces/orders/address.interface";
import {SearchLocationTypeEnum} from "@interfaces/components/enums/search-location-type.enum";
import {CityInterface} from "@interfaces/components/cities.interface";
//services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {CityService} from "@services/city/city.service";
import {ConfirmationDialogService} from "@services/confirmation-dilog/confirmation-dilog.service";
import {IsInGeofenceService} from "@services/is-in-geofence/is-in-geofence.service";
import {BrowserService} from "@services/components/browser.service";
import {AuthService} from "@services/auth/auth.service";

@Component({
  selector: 'app-confirm-address-dialog',
  templateUrl: './confirm-address-dialog.component.html',
  styleUrls: ['./confirm-address-dialog.component.scss'],
  standalone: true,
  imports: [ClearButtonComponent, SearchLocationComponent, InputComponent, NgClass, TranslateModule, NgIf]
})
export class ConfirmAddressDialogComponent {
  @ViewChildren(InputComponent) inputComponent!: QueryList<InputComponent>;
  @ViewChild('nameInputComponent') nameInputComponent!: InputComponent;

  @Input('dataFromMobile') set mobileAddress(data: { fullAddressObject: FullAddressLongLatInterface | FavoriteAddressInterface }) {
    if (data.fullAddressObject) {
      this.data = this.browserService.isMobileDevice ? data : this.data
      this.getAddressFromPassingData(this.data.fullAddressObject);
    }
  }

  @Output() passToMobileMapEmitter = new EventEmitter<FavoriteAddressInterface | null>();
  @Output() closeComponentEmitter = new EventEmitter<void>();

  addressDataObj!: FavoriteAddressInterface;
  cityModel!: CityInterface | FavoriteAddressInterface;
  error: boolean = false;
  searchLocationTypeEnums = SearchLocationTypeEnum

  formData = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    comment: new UntypedFormControl(''),
    entrance: new UntypedFormControl(''),
    floor: new UntypedFormControl(''),
    aptNumber: new UntypedFormControl(''),
    intercom: new UntypedFormControl('')
  });
  favoriteAddressId!: number;
  isMobile!: boolean;

  constructor(private cityService: CityService,
              private requestService: RequestMethodsService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ConfirmAddressDialogComponent>,
              private confirmationDialogService: ConfirmationDialogService,
              private isInGeofenceService: IsInGeofenceService,
              private browserService: BrowserService,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: { fullAddressObject: FavoriteAddressInterface | FullAddressLongLatInterface }
  ) {
    this.isMobile = this.browserService.isMobileDevice;
    const city = this.cityService.getCityModelFromLocalStorage();
    if(city){
      'city' in city ?  this.cityModel = city.city : this.cityModel = city.address;
    }
    if(this.isMobile) { return; }
    this.getAddressFromPassingData(this.data.fullAddressObject);
  }


  getAddressFromPassingData(data: any): void {
    if (data?.id && 'id' in data ) {
      this.favoriteAddressId = data.id;
      this.changingData(data)
    } else {
      this.addressDataObj = {
        name: '',
        ...data
      };
    }

  }

  changingData(favorite: FavoriteAddressInterface): void {
    this.addressDataObj = favorite;
    const data = {
      comment: favorite.comment,
      entrance: favorite.addressDetails?.entrance,
      floor: favorite.addressDetails?.floor,
      aptNumber: favorite.addressDetails?.aptNumber,
      intercom: favorite.addressDetails?.intercom
    }
    this.formData.patchValue(data);
  }

  handleNewLocation(data: FullAddressLongLatInterface | null) {
    if (!data) {
      return;
    }

    this.addressDataObj = {
      fullAddress: data.fullAddress,
      latitude: data.latitude,
      longitude: data.longitude,
      cityName: ''
    }
  }


  close(addressModel: FavoriteAddressInterface | null = null): void {
    if (this.browserService.isMobileDevice) {
      this.passToMobileMapEmitter.emit(addressModel)
    } else {
      this.dialogRef.close(addressModel)
    }
  }

  saveAddressData(): void {
    this.inputComponent.forEach((item) => {
      this.formData.patchValue({
        [item.inputData.data.key]: item.inputData.data.value
      })
    })

    const getFormData = this.formData.value;
    this.addressDataObj = {
      fullAddress: this.addressDataObj.fullAddress,
      latitude: this.addressDataObj.latitude,
      longitude: this.addressDataObj.longitude,
      comment: getFormData.comment,
      cityName: this.addressDataObj.cityName,
      addressDetails: {
        entrance: getFormData.entrance,
        floor: getFormData.floor,
        aptNumber: getFormData.aptNumber,
        intercom: getFormData.intercom,
      }
    }

    if (!this.authService.getUserInfo()) {
      this.passToMobileMapEmitter.emit(this.addressDataObj)
      this.close(this.addressDataObj)
      return;
    }

    let endpoint = `${EndpointConstant.favorite}-${EndpointConstant.addresses}`;

    endpoint += this.favoriteAddressId ? `/${this.favoriteAddressId}` : '';

    const request = this.favoriteAddressId ? this.requestService.put(endpoint, this.addressDataObj) :
      this.requestService.post(endpoint, this.addressDataObj);

    request.subscribe((response:FavoriteAddressInterface) => {
      if(this.favoriteAddressId){
        this.addressDataObj = {...this.addressDataObj,id: this.favoriteAddressId}
      }else{
        this.addressDataObj =  {...response}
      }
      this.passToMobileMapEmitter.emit(this.addressDataObj)
      this.close(this.addressDataObj)
    })
  }

}

<div class="open_component">
  <app-map *ngIf="!openConfirmAddressComponent && !getFullAddress.fullAddressObject?.id"
           [configsForMap]="{id:'map_dialog1',type:mapConfigType}"
           [city]="city"
           (openConfirmationDialogForAddressEmitter)="openConfirmationDialogForAddress($event)"
  >
  </app-map>
  <app-confirm-address-dialog *ngIf="openConfirmAddressComponent || getFullAddress.fullAddressObject?.id"
                              [dataFromMobile]="getFullAddress"
                              (passToMobileMapEmitter)="closeComponent($event)"
                              (closeComponentEmitter)="openConfirmAddressComponent= false"
  ></app-confirm-address-dialog>
</div>

<div class="back_button br_50 flex_center_align_center pointer"
     *ngIf="isMobile"
     (click)="closeComponentEmitter.emit()">
  <img src="assets/images/mobile_icons/navigate-back_icon.svg">
</div>
<div class="address_info_container open_component">
  <app-clear-button [title]="favoriteAddressId? 'order_creation' +
   '' +
    '.address_editing':'order_creation.check_address'" (closeButton)="close()"></app-clear-button>
  <div class="input_container mt_40">
    <div class="w_100 flex_between_align_center">
      <app-search-location
        [type]="searchLocationTypeEnums.CONFIRM_ADDRESS"
        [fullAddress]="addressDataObj?.fullAddress || ''"
        (getLocation)="handleNewLocation($event)"
      >
      </app-search-location>
    </div>

    <div class="flex_between_align_center mt_15">
      <div class="w_48">
        <app-input [inputData]="{
                            type: 'input',
                            label: 'order_creation.delivery_card_info_address_entrance',
                            data: {key:'entrance', value: formData.controls['entrance'].value},
                            placeholder: 'order_creation.delivery_card_info_address_entrance',
                          }">
        </app-input>
      </div>
      <div class="w_48">
        <app-input [inputData]="{
                            type: 'input',
                            label: 'order_creation.delivery_card_info_address_floor',
                            data: {key:'floor', value: formData.controls['floor'].value},
                            placeholder: 'order_creation.delivery_card_info_address_floor',
                          }">
        </app-input>
      </div>
    </div>
    <div class="flex_between_align_center mt_15">
      <div class="w_48">
        <app-input [inputData]="{
                            type: 'input',
                            label: 'order_creation.delivery_card_info_address_apartment',
                            data: {key:'aptNumber', value: formData.controls['aptNumber'].value},
                            placeholder: 'order_creation.delivery_card_info_address_apartment',
                          }">
        </app-input>
      </div>
      <div class="w_48">
        <app-input [inputData]="{
                            type: 'input',
                            label: 'order_creation.delivery_card_info_address_intercom',
                            data: {key:'intercom', value: formData.controls['intercom'].value},
                            placeholder: 'order_creation.delivery_card_info_address_intercom',
                          }">
        </app-input>
      </div>
    </div>
    <div class="comment_block  mt_15">
      <app-input [inputData]="{
                            type: 'textarea',
                            label: 'order_creation.delivery_card_info_address_comment',
                            data: {key:'comment', value: formData.controls['comment'].value},
                            placeholder: 'order_creation.delivery_card_info_address_comment',
                          }">
      </app-input>
    </div>

    <div class="w_100 mt_15" [ngClass]="{save_button_div : !isMobile}">
      <button class="main_buttons save_info flex_center_align_center"
              (click)="saveAddressData()">
        {{'buttons.confirm' | translate}}
      </button>
    </div>
  </div>
</div>

<div class="map_container">
  <div class="close mb_24">
  <app-clear-button [title]="city?.name || 'Выберите адрес'" (closeButton)="close()"></app-clear-button>
  </div>

  <app-map [configsForMap]="{id:'map_dialog',type:mapConfigType}"
           [city]="city"
           (openConfirmationDialogForAddressEmitter)="openConfirmationDialogForAddress($event)"
  ></app-map>

</div>

